<template>
  <div ref="el" v-bind="(ouiaProps as any)" class="list-group-item" :class="{'list-view-pf-expand-active': managedExpanded, 'list-view-pf-expandable': expandable}" @click="handleClick($event as PointerEvent)">
    <pf-button v-if="expandable" variant="link" class="list-view-pf-expand" :class="{'active': managedExpanded}">
      <angle-down-icon v-if="managedExpanded" />
      <angle-right-icon v-else />
    </pf-button>

    <auto-wrap component="div" class="list-view-pf-main-info" :include="PfDataListCell">
      <slot />
    </auto-wrap>
  </div>
</template>

<style scoped>
.list-view-pf-expandable {
  cursor: pointer;
}
</style>

<script lang="ts">
export const DataListItemExpandedKey = Symbol('DataListItemKey') as InjectionKey<WritableComputedRef<boolean>>;

export interface Props extends OUIAProps, /* @vue-ignore */ HTMLAttributes {
  /** Flag indicating if the pf-data-list-item is expandable */
  expandable?: boolean;
  /** Flag to show if the expanded content of the pf-data-list-item is visible */
  expanded?: boolean;
}
</script>

<script lang="ts" setup>
import { provide, type HTMLAttributes, type InjectionKey, type WritableComputedRef, ref, type Ref } from "vue";
import { useOUIAProps, type OUIAProps } from "../ouia";
import AngleRightIcon from '@vue-patternfly/icons/angle-right-icon';
import AngleDownIcon from '@vue-patternfly/icons/angle-down-icon';
import { useManagedProp } from "../use";
import AutoWrap from "./AutoWrap.vue";
import PfDataListCell from "./PfDataListCell.vue";

const props = withDefaults(defineProps<Props>(), {
  expanded: undefined,
  expandable: undefined,
});
const ouiaProps = useOUIAProps(props);

defineSlots<{
  default?: (props?: Record<never, never>) => any;
}>();

defineEmits<{
  (name: 'update:expanded', s: boolean): void;
}>();

const managedExpanded = useManagedProp('expanded', false);
provide(DataListItemExpandedKey, managedExpanded);

const el: Ref<HTMLElement | undefined> = ref(undefined);

function handleClick(e: PointerEvent) {
  const itemContainer = el.value?.querySelector('.list-group-item-container');
  if (!(e.target instanceof Element) || !itemContainer?.contains(e.target)) {
    managedExpanded.value = !managedExpanded.value;
  }
}
</script>
