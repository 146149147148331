<template>
  <login-layout variant="large">
    <header class="login-pf-header">
      <h1>{{ _t('Richiesta di accesso') }}</h1>
      <h4 v-if="login.approval === null">{{ _t('Ti chiediamo di fornirci alcuni dati per migliorare ulteriormente il tuo profilo.') }}</h4>
    </header>

    <h2><ok-icon v-if="login.complete" /> 1. {{ _t('Dati Aziendali') }}</h2>
    <billing-form v-if="!login.complete" embed-for="approval" modal />

    <h2 v-if="openSkill || showFinalMessage">
      <ok-icon v-if="submittedSkill" /> 2. {{ skillTitle }}
      <pf-button v-if="submittedSkill" variant="link" class="pull-right" @click="submittedSkill = false">{{ _t('Modifica') }}</pf-button>
    </h2>
    <skill-form
      v-if="openSkill"
      :required="login.approval === 'in approvazione rivenditore'"
      @submit="submittedSkill = true"
    />

    <div v-else-if="showFinalMessage" class="approval_thanks">
      <p>{{ _t('Grazie per aver risposto alle nostre domande.') }}</p>
      <p>{{ _t('La tua richiesta è in fase di valutazione, sarai contattato a breve da un nostro consulente.') }}</p>
    </div>
    <a class="btn-logout pull-right" href="/api/auth/logout">{{ $t('Logout') }}</a>
  </login-layout>
</template>

<style scoped>
.approval_thanks {
  font-size: 16px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 50px;
}
.btn-logout{
  margin-top: -16px;
}
</style>

<script lang="ts" setup>
import LoginLayout from '../../components/login-layout.vue';
import BillingForm from '../../components/billing-form.vue';
import SkillForm from '../../components/skill-form.vue';
import { useLoginStore } from '../../store/login';
import { $t as _t } from '@/i18n';
import { loginDestination } from '../../utils';
import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount, ref, computed } from 'vue';
import OkIcon from '@vue-patternfly/icons/ok-icon';

const login = useLoginStore();
const router = useRouter();
const route = useRoute();

const submittedSkill = ref(false);

const openSkill = computed(() => login.complete && !submittedSkill.value);
const skillTitle = computed(() => {
  if (login.approval === 'in approvazione cliente') {
    return _t('Skill richieste');
  }
  if (login.approval === 'in approvazione rivenditore') {
    return _t('Le tue skill');
  }
  return _t('Skill');
});

const showFinalMessage = computed(() => {
  return login.complete && !openSkill.value;
});

onBeforeMount(() => {
  if (login.approval === 'approvato') {
    router.push(loginDestination(route.query));
    return;
  }

  submittedSkill.value = login.complete_skill;
});
</script>
