<template>
  <pf-layout ref="layout" collapsable icons :disabled="app.maintenance || $route.meta.fullPageWithBg as boolean || !loggedIn">
    <template #brand>
      <router-link :to="{ name: 'dashboard', params: { id_cliente: company.id } }" class="navbar-brand">
        <img src="@/assets/logo.svg" width="32" height="32" alt="Artera">
        <span>{{ company.name }} - {{ $t('Codice') }}: {{ company.code }}</span>
      </router-link>
    </template>

    <!-- Sidebar -->
    <template #vertical-menu>
      <granted-link
        menu
        :to="{ name: 'dashboard', params: { id_cliente: company.id } }"
      >
        <template #icon><house-icon /></template>
        {{ $t('Dashboard') }}
      </granted-link>

      <granted-link menu :to="{ name: 'catalog' }">
        <template #icon><cart-shopping-icon /></template>
        {{ $t('Listino prezzi') }}
      </granted-link>

      <granted-link menu :to="{ name: 'diy-offer' }">
        <template #icon><wand-magic-icon /></template>
        {{ $t('Offerta fai da te') }}
      </granted-link>

      <granted-link
        menu
        :to="{ name: 'services', params: { id_cliente: company.id } }"
      >
        <template #icon><server-icon /></template>
        {{ $t('My hosting') }}
      </granted-link>

      <granted-link
        menu
        :to="{ name: 'servers', params: { id_cliente: company.id } }"
      >
        <template #icon><cloud-icon /></template>
        {{ $t('My cloud') }}
      </granted-link>

      <granted-link
        menu
        :to="{ name: 'products', params: { id_cliente: company.id } }"
      >
        <template #icon><layer-group-icon /></template>
        {{ $t('I miei prodotti') }}
      </granted-link>

      <granted-link menu :to="{ name: 'offers' }">
        <template #icon><file-lines-icon /></template>
        {{ $t('Offerte e ordini') }}
      </granted-link>

      <nav-group :title="$t('Amministrazione')">
        <granted-link menu :to="{ name: 'invoices' }">
          <template #icon><file-invoice-dollar-icon /></template>
          {{ $t('Fatture') }}
        </granted-link>

        <granted-link menu :to="{ name: 'account_movements' }">
          <template #icon><wallet-icon /></template>
          {{ $t('Conto Artera') }}
        </granted-link>

        <granted-link menu :to="{ name: 'documents' }">
          <template #icon><file-signature-icon /></template>
          {{ $t('Documenti utili') }}
        </granted-link>
      </nav-group>

      <nav-group :title="$t('Area partner')">
        <granted-link
          menu
          :to="{ name: 'my_customers' }"
        >
          <template #icon>
            <circle-arrow-left-icon v-if="company.id" />
            <users-icon v-else />
          </template>
          {{ company.id ? $t('Torna alla lista clienti') : $t('I miei clienti') }}
        </granted-link>

        <granted-link
          menu
          :to="{name: 'introduction'}"
        >
          <template #icon><user-icon /></template>
          {{ $t('La tua azienda') }}
        </granted-link>

        <granted-link
          menu
          :to="{name: 'my_skills'}"
        >
          <template #icon><puzzle-piece-icon /></template>
          {{ $t('Le mie skill') }}
        </granted-link>

        <granted-link
          menu
          :to="{name: 'opportunities'}"
        >
          <template #icon><thumb-tack-icon /></template>
          {{ $t('Opportunità') }}
        </granted-link>
      </nav-group>

      <granted-link
        menu
        :to="{ name: 'become_partner' }"
      >
        <template #icon><handshake-icon /></template>
        {{ $t('Diventa partner') }}
      </granted-link>

      <granted-link
        menu
        :to="{ name: 'projects' }"
      >
        <template #icon><pen-ruler-icon /></template>
        {{ $t('Presenta un progetto') }}
      </granted-link>

      <template v-if="!company.id">
        <granted-link menu :to="{ name: 'tickets' }">
          <template #icon><ticket-icon /></template>
          {{ $t('Tickets') }}
        </granted-link>

        <granted-link menu :to="{ name: 'security' }">
          <template #icon><unlock-icon /></template>
          {{ $t('Sblocco IP') }}
        </granted-link>

        <template v-if="!$route.meta.disableNavigation">
          <granted-link
            class="visible-xs-block"
            menu
            :to="{ name: 'profile' }"
          >
            <template #icon><user-icon /></template>
            {{ $t('Modifica profilo') }}
          </granted-link>
          <granted-link
            class="visible-xs-block"
            menu
            :to="{ name: 'company', params: { id_cliente: company.id } }"
          >
            <template #icon><file-invoice-dollar-icon /></template>
            {{ $t('Dati di fatturazione') }}
          </granted-link>
          <granted-link
            class="visible-xs-block"
            menu
            :to="{ name: 'users' }"
          >
            <template #icon><users-icon /></template>
            {{ $t('Utenze') }}
          </granted-link>
        </template>

        <granted-link
          v-if="aziendeCount > 1"
          class="visible-xs-block"
          menu
          :to="{ name: 'login_azienda' }"
        >
          <template #icon><right-to-bracket /></template>
          {{ $t('Cambia Azienda') }}
        </granted-link>

        <pf-menu-item class="visible-xs-block" vertical icon="fa-sign-out" :title="$t('Esci')" @click="app.logout()" />
      </template>
    </template>

    <template #utility-menu>
      <li v-if="company.id" v-cloak class="nav-item-iconic">
        <span>
          {{ $t('Sei loggato come') }}:<br>
          <strong>{{ login.azienda }}</strong>
        </span>
      </li>

      <pf-dropdown tag="li" btn-class="nav-item-iconic" type="link">
        <template #text>
          <user-icon />
          {{ ' ' }}
          <span class="dropdown-title">
            <strong>{{ login.username }}</strong>
          </span>
        </template>

        <template v-if="!$route.meta.disableNavigation">
          <li>
            <granted-link :to="{ name: 'profile' }">
              {{ $t('Modifica profilo') }}
            </granted-link>
          </li>
          <li>
            <granted-link :to="{ name: 'company', params: { id_cliente: company.id } }">
              {{ $t('Dati di fatturazione') }}
            </granted-link>
          </li>
          <li>
            <granted-link :to="{ name: 'users' }">
              {{ $t('Utenze') }}
            </granted-link>
          </li>
        </template>

        <li v-if="aziendeCount > 1">
          <granted-link :to="{ name: 'login_azienda' }">
            {{ $t('Cambia Azienda') }}
          </granted-link>
        </li>
        <li>
          <granted-link :to="{ name: 'webmail' }" target="_blank">
            {{ $t('Webmail') }}
          </granted-link>
        </li>

        <li @click="app.logout()">
          <a>{{ $t('Esci') }}</a>
        </li>
      </pf-dropdown>
    </template>

    <pf-notifications ref="notifications" toast />

    <pf-notifications v-cloak v-if="loggedIn && !app.isInIframe && login.contratto_cliente && login.approval === 'approvato' " class="persistent-notifications">
      <pf-notification v-if="!login.complete" type="warning">
        <granted-link class="pull-right btn btn-link" :to="{ name: 'company', params: { id_cliente: company.id } }">
          {{ $t('Dati di fatturazione') }}
        </granted-link>

        <strong>{{ $t('Attenzione!') }}</strong>
        {{ $t('I dati di fatturazione sono incompleti e vanno compilati per poter ricevere le fatture.') }}
      </pf-notification>

      <pf-notification v-else-if="!login.compiledSurvey" type="warning">
        <i18n-t
          keypath="Compila il questionario annuale e sblocca tutti i vantaggi {0}">
          <pf-button variant="link" @click="showSurveyModal = true">{{ $t('Clicca qui per compilarlo') }}</pf-button>
        </i18n-t>
      </pf-notification>
    </pf-notifications>

    <maintenance-page v-if="app.maintenance" />
    <router-view v-else />
  </pf-layout>

  <top-progress ref="progress" color="#ffffff" />

  <pf-modal
    v-if="showSurveyModal"
    show
    :title="$t('Compila il questionario')"
    outside-close
    confirm-button=""
    cancel-button=""
    @cancel="showSurveyModal = false">
    <introduction-form variant="survey" @submit="submittedSurvey" />
  </pf-modal>
</template>

<script lang="ts">
import { ref, defineComponent, type Ref } from 'vue';
import { http } from './http';
import MaintenancePage from './pages/maintenance.vue';
import GrantedLink from './components/granted-link.vue';
import NavGroup from './components/nav-group.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import UserIcon from '@vue-patternfly/icons/user-icon';
import PuzzlePieceIcon from '@vue-patternfly/icons/puzzle-piece-icon';
import RightToBracket from '@vue-patternfly/icons/right-to-bracket-icon';
import UsersIcon from '@vue-patternfly/icons/users-icon';
import FileLinesIcon from '@vue-patternfly/icons/file-lines-icon';
import HouseIcon from '@vue-patternfly/icons/house-icon';
import FileInvoiceDollarIcon from '@vue-patternfly/icons/file-invoice-dollar-icon';
import FileSignatureIcon from '@vue-patternfly/icons/file-signature-icon';
import UnlockIcon from '@vue-patternfly/icons/unlock-icon';
import ThumbTackIcon from '@vue-patternfly/icons/thumb-tack-icon';
import CloudIcon from '@vue-patternfly/icons/cloud-icon';
import HandshakeIcon from '@vue-patternfly/icons/handshake-icon';
import TicketIcon from '@vue-patternfly/icons/ticket-icon';
import LayerGroupIcon from '@vue-patternfly/icons/layer-group-icon';
import CircleArrowLeftIcon from '@vue-patternfly/icons/circle-arrow-left-icon';
import WalletIcon from '@vue-patternfly/icons/wallet-icon';
import CartShoppingIcon from '@vue-patternfly/icons/cart-shopping-icon';
import WandMagicIcon from '@vue-patternfly/icons/wand-magic-icon';
import ServerIcon from '@vue-patternfly/icons/server-icon';
import PenRulerIcon from '@vue-patternfly/icons/pen-ruler-icon';

import TopProgress from './components/top-progress.vue';
import { Notifications as PfNotifications } from 'vue-patternfly';
import { useAppStore } from './store/app';
import { useCompanyStore } from './store/company';
import { useLoginStore } from './store/login';
import { usePaypalStore } from '@/store/paypal';
import { pop } from '@common/utils';
import { CompanyAccessResource } from './resources';
import IntroductionForm from './components/introduction-form.vue';

export default defineComponent({
  name: 'AdminApp',

  components: {
    PfNotifications,
    TopProgress,
    MaintenancePage,
    GrantedLink,
    UserIcon,
    PuzzlePieceIcon,
    RightToBracket,
    UsersIcon,
    FileLinesIcon,
    HouseIcon,
    FileInvoiceDollarIcon,
    FileSignatureIcon,
    UnlockIcon,
    ThumbTackIcon,
    CloudIcon,
    HandshakeIcon,
    TicketIcon,
    WalletIcon,
    CircleArrowLeftIcon,
    LayerGroupIcon,
    CartShoppingIcon,
    WandMagicIcon,
    ServerIcon,
    PenRulerIcon,
    NavGroup,
    IntroductionForm,
  },

  setup() {
    const app = useAppStore();
    const company = useCompanyStore();
    const login = useLoginStore();
    const paypal = usePaypalStore();
    const notifications = ref(null);
    const progress: Ref<InstanceType<typeof TopProgress> | null> = ref(null);

    paypal.load();

    return {
      app,
      notifications,
      company,
      login,
      progress,
      ...setupAsyncComputed({
        aziendeCount: {
          async get(this: any) {
            if (!this.login.id) {
              return 0;
            }
            const response = await new CompanyAccessResource().get();
            return Object.keys(response).length;
          },
          default: 0,
        },
      }),
    };
  },

  data(this: void) {
    return {
      navbarCollapsed: false,
      showSurveyModal: false,
    };
  },

  computed: {
    loggedIn() {
      return Boolean(this.login.id_azienda);
    },

    bodyClass() {
      if (this.app.maintenance || this.$route.meta.fullPageWithBg) {
        const embed = pop(this.$route.query.embed);
        if (embed && ['promo-hosting-premium', 'footer-arteranet', 'sito-footer', 'home-arteranet', 'sito-home', 'sito-registradominio', 'landing-shared-hosting', 'landing-seeweb-swiss'].includes(embed)) {
          return embed;
        }
        return 'login-pf';
      }
      return '';
    },
  },

  watch: {
    notifications() {
      this.app.notifications = this.notifications;
    },

    $router: {
      handler() {
        this.app.router = this.$router;
      },
      immediate: true,
    },

    $route: {
      handler(route) {
        if (route.params.id_cliente != this.company.id) {
          if (route.params.id_cliente) {
            this.company.fetch(route.params.id_cliente);
          } else {
            this.company.$reset();
          }
        }
      },
      immediate: true,
    },

    bodyClass(to, from) {
      if (from) {
        document.documentElement.classList.remove(from);
      }
      if (to) {
        document.documentElement.classList.add(to);
      }
    },

    'app.loading': {
      immediate: true,
      handler(to, from) {
        if (!this.progress) {
          return;
        }

        if (!to) {
          this.progress.done();
          return;
        } else if (!from) {
          this.progress.start();
          return;
        }

        const progress = this.progress.getProgress();
        this.progress.increase(((100 - progress) / from) * from - to);
      },
    },
  },

  beforeCreate() {
    http.interceptors.request.use(config => {
      if (!config.params) {
        config.params = {};
      }
      if (this.$route.params.id_cliente) {
        config.params.id_cliente = this.$route.params.id_cliente;
      }
      return config;
    });
  },

  created() {
    window.addEventListener('resize', this.windowResized);
    this.windowResized();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.windowResized);
  },

  mounted() {
    this.$watch(
      '$route.meta.disableNavigation',
      (disabled: boolean) => {
        (this.$refs.layout as any).collapsed = disabled;
      },
      {
        immediate: true,
      },
    );
  },

  methods: {
    windowResized() {
      this.navbarCollapsed = window.innerWidth <= 767;
    },

    submittedSurvey() {
      this.showSurveyModal = false;
      this.login.refresh();
    },
  },
});
</script>
