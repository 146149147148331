import { DateTime, Settings } from 'luxon';

export function parseDateTime(date: string | number | Date | DateTime): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  const dateAsNum = Number(date);

  if (typeof date === 'number' || !isNaN(dateAsNum)) {
    if (dateAsNum < 10000000000) {
      return DateTime.fromSeconds(dateAsNum);
    }
    return DateTime.fromMillis(dateAsNum);
  }

  if (typeof date === 'string') {
    if (/^\d{4}-\d{2}-\d{2} \d{2}/.test(date)) {
      date = date.substring(0, 10) + 'T' + date.substring(11);
    }
    return DateTime.fromISO(date);
  }

  return DateTime.fromJSDate(date);
}

export function getToday() {
  return DateTime.local().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

export const today = getToday();

export function setDefaultFixedTimezone() {
  const local = DateTime.local();
  Settings.defaultZone = `UTC${local.zone.formatOffset(local.toMillis(), 'narrow')}`;
}

export function daysFromNow(value: string | number | Date | DateTime, to?: string | number | Date | DateTime | null) {
  return parseDateTime(value).toRelative({unit: 'days', base: to ? parseDateTime(to) : undefined}) ?? String(value)
}
