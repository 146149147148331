<template>
  <payment-cashback
    v-if="enableCashback"
    v-model="cashback"
    :totale-offerta-ivato="totaleOffertaIvato"
    :currency="currency"
  />

  <input
    v-if="!allowedMethods.length || asyncComputed.company.updating"
    class="input-hidden"
    type="radio"
    required
    :name="name"
    :checked="false"
    @click.prevent
  />

  <div v-if="asyncComputed.company.updating" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ $t('Caricamento metodi di pagamento...') }}
    </h3>
  </div>
  <div v-else>
    <label class="control-label" style="margin-top: 1em">{{ $t('Metodo di pagamento') }}</label>

    <div v-if="allowedMethods.includes('custom')" class="radio">
      <label>
        <input
          id="payCustom"
          :checked="modelValue == 'custom'"
          type="radio"
          :name="name"
          value="custom"
          required
          @change="change"
        >
        {{ paymentCustomLabel }}
      </label>
    </div>

    <template v-else>
      <div v-if="allowedMethods.includes('paypal')" class="radio">
        <label>
          <input
            id="payPayPal"
            :checked="modelValue == 'paypal'"
            type="radio"
            :name="name"
            value="paypal"
            required
            @change="change"
          >
          PayPal
          <paypal-icon />
        </label>
      </div>

      <div v-if="allowedMethods.includes('axerve-paypal')" class="radio">
        <label>
          <input
            id="payPayPal"
            :checked="modelValue == 'axerve-paypal'"
            type="radio"
            :name="name"
            value="axerve-paypal"
            required
            @change="change"
          >
          PayPal
          <paypal-icon />
        </label>
      </div>

      <template v-if="allowedMethods.includes('axerve-cc')">
        <div class="radio">
          <label>
            <input
              id="payCc"
              :checked="modelValue == 'axerve-cc'"
              type="radio"
              :name="name"
              value="axerve-cc"
              required
              @change="change"
            >
            {{ $t('Carta di credito') }}
            <cc-mastercard-icon />{{ ' ' }}<cc-visa-icon />{{ ' ' }}
          </label>
        </div>
        <div v-if="askRecurring">
          <label>
            <input type="checkbox" name="enable_recurring_payments"> {{ $t('Autorizza i pagamenti ricorrenti automatici fino ad un massimo di 10.000 €/CHF') }}
            <form-help-tooltip :text="$t('Selezionando questa opzione e completando il pagamento, tutte le prossime scadenze di pagamento, fino ad un massimo di 10.000 €/CHF, verranno automaticamente saldate.')" />
          </label>
        </div>
        <div v-else-if="login.has_recurring_payments && invoice">
          <span>{{ $t('NB: Ti ricordiamo che sono attivi i pagamenti automatici. Questa scadenza verrà saldata automaticamente dal sistema con il metodo da te scelto.') }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { defineComponent, type PropType } from 'vue';
import { useCompany } from '../utils';
import { useLoginStore } from '@/store/login';
import { $t } from '@/i18n';
import PaymentCashback from './payment-cashback.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import PaypalIcon from '@vue-patternfly/icons/paypal-icon';
import CcVisaIcon from '@vue-patternfly/icons/cc-visa-icon';
import CcMastercardIcon from '@vue-patternfly/icons/cc-mastercard-icon';

export type Methods = 'axerve-cc' | 'axerve-paypal' | 'paypal' | 'custom';

export default defineComponent({
  name: 'PaymentMethods',

  components: {
    PaymentCashback,
    FormHelpTooltip,
    PaypalIcon,
    CcVisaIcon,
    CcMastercardIcon,
  },

  props: {
    name: {
      type: String,
      default: 'method',
    },
    modelValue: {
      type: String as PropType<Methods>,
      default: null,
    },
    invoice: {
      type: Boolean,
      default: false,
    },
    freePeriod: {
      type: Number,
      default: 0,
    },
    testingMode: {
      type: String as PropType<'rinnova' | 'disdici'>,
      default: 'rinnova',
    },
    enableCashback: Boolean,
    totaleOffertaIvato: {
      type: Number,
      required: true,
    },
    currency: {
      type: String as PropType<'CHF' | 'EUR'>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        company: useCompany(),
      }),
    };
  },

  data() {
    return {
      cashback: 0,
    };
  },

  computed: {
    allowedMethods() {
      return this.allowCustom ? ['custom'] : (this.company.metodi_pagamento || []);
    },

    allowCustom() {
      return this.freePeriod || (this.company.cod_pagamento !== 'RD000' && !this.invoice);
    },

    askRecurring() {
      return (this.modelValue === 'axerve-cc' || this.modelValue === 'axerve-paypal') && !this.login.has_recurring_payments;
    },

    paymentCustomLabel() {
      if (this.freePeriod) {
        return this.testingMode === 'rinnova' ?
          $t('GRATIS. Se non disdici prima ti arriverà fattura tra {0} giorni', [this.freePeriod]) :
          $t('GRATIS per {0} giorni. Se non ci chiederei il rinnovo i servizi verranno sospesi e cancellati tra {0} giorni', [this.freePeriod]);
      }

      if (!this.company.fatturazione_mensile) {
        return this.company.pagamento;
      }

      return `${$t(this.company.pagamento)} - ${$t('incluso in fatturazione mensile')}`;
    },
  },

  watch: {
    'allowedMethods.length': {
      immediate: true,
      handler(v) {
        if (v === 1) {
          this.$emit('update:modelValue', this.allowedMethods[0]);
        }
      },
    },
  },

  methods: {
    change(e: Event) {
      if (e.target instanceof HTMLInputElement) {
        this.$emit('update:modelValue', e.target.value);
      }
    },
  },
});
</script>
