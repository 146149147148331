<template>
  <page
    :name="$t('Opportunità')"
    :description="$t('Qui trovi la lista di tutte le possibili opportunità e leads che ti abbiamo segnalato')"
    :state="opportunitiesState"
    :loading="opportunitiesState.updating"
  >
    <project-list v-if="opportunities.length" variant="opportunity" :projects="opportunities" @feedback="opportunitiesState.update()" />

    <div v-else class="blank-slate-pf">
      <p>{{ $t('Al momento non ci sono opportunità o lead.') }}</p>
      <p>{{ $t('Se non l\'hai ancora fatto compila le tue skill e abilità nella sezione "Skill" e aiutaci a inviarti segnalazioni e possibili nuovi clienti') }}</p>
      <div class="blank-slate-pf-main-action">
        <router-link :to="{ name: 'my_skills' }" class="btn btn-primary btn-lg">{{ $t('Vai alle Skill') }}</router-link>
      </div>
    </div>
  </page>
</template>

<style scoped>
:deep(.list-view-pf-additional-info-item) {
  align-items: flex-start;
}
</style>

<script lang="ts" setup>
import { asyncComputedList } from '@common/asyncComputed';
import { OpportunityResource } from '@/resources';
import Page from '../components/page.vue';
import ProjectList from '../components/project-list.vue';

const { state: opportunitiesState, result: opportunities } = asyncComputedList(() => new OpportunityResource().get());
</script>
