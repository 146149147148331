<template>
  <div>
    <template v-if="currentStep === 'WizardStepScopo'">
      <p>{{ $t('Benvenuto nel wizard di Artera!') }}</p>
      <p>
        {{ $t('Grazie a questo strumento potrai strutturare un preventivo personalizzato in base alle tue esigenze.') }}
      </p>
    </template>
    <div class="wizard-pf-body">
      <div v-if="!ready" class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Stiamo caricando il wizard...') }}
        </h3>
        <p class="blank-slate-pf-secondary-action">
          {{ $t('Ancora pochi istanti e potrai costruire il tuo preventivo.') }}
        </p>
      </div>
      <template v-else>
        <wizard-step-scopo />
        <wizard-step-farm />
        <wizard-step-prodotti-infrastruttura />
        <wizard-step-stack />
        <wizard-step-hardening />
        <wizard-step-mail />
        <wizard-step-addon />
        <wizard-step-dominio />
        <wizard-step-clienti v-if="askClienti" />
        <wizard-step-summary />
        <wizard-step-thankyou />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.question-wrapper {
  margin: 30px 0;
}
.question-wrapper p,
.step-description {
  max-width: 1000px;
}
.radio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1000px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.radio-description {
  padding: 0 0 10px 16px;
}
@media (min-width: $screen-xs-max) {
  .radio-wrapper > li {
    width: 20%;
  }
}
@media (max-width: $screen-xs-max) {
  .radio-wrapper {
    flex-direction: column;
  }
}

.product-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-wrapper > div {
  width: 50%;
}

.product-column {
  margin-top: 25px;
}

@media (max-width: $screen-md-max) {
  .product-wrapper {
    flex-direction: column;
  }

  .product-wrapper > div {
    margin-bottom: 25px;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { defineComponent, toValue, type PropType, type InjectionKey } from 'vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { provideChildrenTracker, useResettableState } from '@common/use';
import { by } from '@common/utils';
import { $t } from '../../i18n';
import { WizardStepsTrackerSymbol } from './composition';

import WizardStepScopo from './steps/wizard-step-scopo.vue';
import WizardStepFarm from './steps/wizard-step-farm.vue';
import WizardStepProdottiInfrastruttura from './steps/wizard-step-prodotti-infrastruttura.vue';
import WizardStepStack from './steps/wizard-step-stack.vue';
import WizardStepHardening from './steps/wizard-step-hardening.vue';
import WizardStepDominio from './steps/wizard-step-dominio.vue';
import WizardStepAddon from './steps/wizard-step-addon.vue';
import WizardStepMail from './steps/wizard-step-mail.vue';
import WizardStepClienti from './steps/wizard-step-clienti.vue';
import WizardStepSummary from './steps/wizard-step-summary.vue';
import WizardStepThankyou from './steps/wizard-step-thankyou.vue';
import { useCompanyStore } from '@/store/company';
import { useLoginStore } from '@/store/login';
import { CatalogResource, MyCustomersResource, OffersResource, type CatalogProduct, type Offer, type MyCustomersAccess } from '@/resources';

export interface Path {
  label: string;
  description: string;
}

export interface Paths {
  hosting: Path;
  cloud: Path;
  dominio: Path | null;
  mail: Path | null;
  storage: Path | null;
  altro: Path | null;
}

export interface ProductCodesByInfraType {
  hosting: Record<OperativeSystems, string[]>;
  cloud: Record<'linux', string[]>;
  storage: Record<'linux', string[]>;
}

export interface GroupedProducts {
  dominio: {
    items: Product[];
  };
  infrastruttura: {
    items: Product[];
  };
  addon: {
    items: Product[];
  };
  mail: {
    items: Product[];
  };
}

export type Durata = 'Annuale'|'Mensile';
export type OperativeSystems = 'linux'|'windows';
export type OpzioniDomainAction = 'registrazione' | 'dns' | undefined;
export type OpzioniStack = 'essential' | 'cockpit' | 'whm' | 'plesk linux' | 'plesk windows' | 'windows' | 'mailarchive';
type OpzioniAction ='confirm' | 'pdf' | 'mailme' | 'mailcustomer';


const webServers = ['Apache', 'NGINX'] as const;
const cockpitOperativeSystems = ['AlmaLinux', 'Debian', 'Ubuntu'] as const;

const paths = {
  dominio: {
    label: $t('Solo dominio e DNS'),
    description: $t(
      'Per registrare il tuo nome a dominio, tra oltre 200 estensioni TLD. Il servizio include la gestione DNS e la possibilità di gestire il redirect.',
    ),
  },
  mail: {
    label: $t('Solo posta (dominio opzionale)'),
    description: $t(
      'Per attivare il tuo servizio di posta elettronica personale sul tuo nome a dominio.',
    ),
  },
  hosting: {
    label: $t('Hosting e Posta (dominio opzionale)'),
    description:$t('Per attivare il tuo piano hosting su un server condiviso per il tuo sito internet. Include anche la gestione della posta elettronica, backup e certificato SSL.'),
  },
  cloud: {
    label: $t('Cloud (dominio e posta opzionali)'),
    description:$t('Per configurare il tuo cloud server dedicato al tuo sito internet, e-commerce o applicazione. Servizio ottimizzato sulla base delle tue esigenze.'),
  },
  storage: {
    label: $t('Storage & Backup'),
    description: $t(
      'Per attivare subito spazio di archiviazione sicuro e ridondato per gestire i tuoi files e i backup dei dati critici personali ed aziendali.',
    ),
  },
  altro: {
    label: $t('Altro'),
    description: $t(
      'Licenze e certificati SSL',
    ),
  },
};

const opzioniHardening = [
  'Akeneo',
  'Craft CMS',
  'Delphi',
  'Django',
  'Drupal',
  'Elasticsearch',
  'Ionic',
  'Joomla',
  'Kirby',
  'Laravel',
  'Magento 1',
  'Magento 2',
  'MongoDB',
  'Moodle',
  'MySQL',
  'Nextcloud + ONLYOFFICE',
  'Node.js',
  'Odoo',
  'ONLYOFFICE',
  'OroCommerce',
  'PHP',
  'PostgreSQL',
  'PrestaShop',
  'Python',
  'Ruby',
  'Rust',
  'Shopware',
  'Siberian',
  'Statmic',
  'Sulu',
  'Sylius',
  'Symphony',
  'TYPO3',
  'WooCommerce',
  'WordPress',
  'Wordpress multisite',
] as const;

// I primi due caratteri dell'indice devono rappresentare l'ISO di uno stato per mostrarne la bandiera
const farmProduzione = {
  chzu: $t('Zurigo - Svizzera'),
  chlu: $t('Lugano - Svizzera'),
  itmi: $t('Milano - Italia'),
};

const opzioniFarmBackup = {
  chzu: $t('Zurigo - Svizzera'),
  chlu: $t('Lugano - Svizzera'),
  itro: $t('Roma - Italia'),
  silu: $t('Lubiana - Slovenia'),
};

const opzioniFarmMondo = {
  gb: $t('Regno Unito'),
  de: $t('Germania'),
  fr: $t('Francia'),
  pl: $t('Polonia'),
  es: $t('Spagna'),
  se: $t('Svezia'),
  nl: $t('Paesi Bassi'),
  us: $t('Stati Uniti'),
  ca: $t('Canada'),
  mx: $t('Messico'),
  br: $t('Brasile'),
  jp: $t('Giappone'),
  kr: $t('Corea del sud'),
  sg: $t('Singapore'),
  in: $t('India'),
  au: $t('Australia'),
  za: $t('Sud Africa'),
};

type OpzioniFarmProduzione = typeof farmProduzione & { altro?: string; };

type InfoWizard = {
  nomeDominio: string;
  action: string;
  hardening?: string[];
  os?: string;
  webserver?: 'Apache'|'NGINX';
  farm?: string;
  farmBackup?: string;
  note?: string;
  prodotti: {
    codice: string,
    qt: number,
  }[];
}

const codiciProdottiInfrastruttura: ProductCodesByInfraType = {
  hosting: {
    linux: ['HOSMN', 'HOSMRS', 'HOSVEN', 'HOSERT'],
    windows: ['HOSBASW', 'HOSPREMWIN'],
  },
  cloud: {
    linux:  ['FDC1120', 'FDC2240', 'FDC4480', 'FDC48160', 'FDC816320', 'FDC1224400', 'FDC1632600', 'FDC2448760', 'FDC32641000', 'FDCHOST'],
  },
  storage: {
    linux: ['STBCKACR', 'STBCKR1B', 'STBCKIBM', 'STBCKVEE', 'STOCOS', 'STOCSOFP', 'STOCSOFV', 'MLARCH100'],
  },
};

const codiciProdottiMail = ['MLART10Y', 'MLART20Y', 'MLART40Y', 'MLART60Y', 'MLART80Y', 'MLART100Y', 'MLART120'];

const codiciProdottiAddon = {
  registrazionePremium: 'REGPREMY',
  ssl: ['SSLBAS', 'SSLART', 'SSLPREMY', 'SSLWLD'],
  stack: {
    essential: {
      codici: ['AHSESS'],
      descrizione: $t("Questo stack include il monitoraggio essenziale dei parametri vitali e i backup di sicurezza giornalieri, assicurandoti tranquillità e prestazioni stabili per le tue applicazioni."),
    },
    cockpit: {
      codici: ['AHSCCKP'],
      descrizione: $t("Con NGINX, Percona Server for MySQL, Redis e il pannello Cockpit, questo stack offre prestazioni elevate e una gestione semplificata. Include sonde avanzate per un monitoraggio proattivo e gestione personalizzata dei backup, garantendo massima sicurezza e reattività."),
    },
    whm: {
      codici: ['AHSWHM1', 'AHSWHM5', 'AHSWHM30', 'AHSWHM100', 'AHSWHM250', 'AHSWHM500'],
      descrizione: $t("Perfetto per l'hosting reseller, combina CloudLinux OS e WHM / cPanel per una gestione avanzata. Include supporto specialistico e tutte le funzionalità avanzate del cloud hosting Artera, rendendolo ideale per chi cerca una soluzione completa e affidabile."),
    },
    'plesk linux': {
      codici: ['AHSPLK1', 'AHSPLK30', 'AHSPLK500'],
      descrizione: $t("Questo stack fornisce un pannello di controllo intuitivo con Plesk per Linux, ottimizzato per la gestione di siti web, applicazioni e server di posta. Offre strumenti avanzati e supporto specialistico, ideale per professionisti e agenzie che desiderano efficienza."),
    },
    'plesk windows': {
      codici: ['AHSPLKW1', 'AHSPLKW30', 'AHSPLKW500'],
      descrizione: $t("Questo stack fornisce un pannello di controllo intuitivo con Plesk per Windows, ottimizzato per la gestione di siti web, applicazioni e server di posta. Offre strumenti avanzati e supporto specialistico, ideale per professionisti e agenzie che desiderano efficienza."),
    },
    windows: {
      codici: ['AHSWIN'],
      descrizione: $t("Questo stack fornisce una licenza Windows."),
    },
    mailarchive: {
      codici: ['MLARCHSRV'],
      descrizione: $t("Attiva un server di archiviazione email privato con un potente motore di ricerca. Atlas Mail Archive è perfetto per archiviare e recuperare facilmente tutte le email aziendali, garantendo sicurezza e conformità normativa nel flusso di comunicazioni."),
    },
  },
  imunify360: ['AHSIM3601', 'AHSIM36030', 'AHSIM360250', 'AHSIM360500'],
  liteSpeed: 'AHSLITE',
  terminalServer: 'CLDRD',
  soloDNS: 'HOSDOM',
};

export type Product = CatalogProduct & {
  prezzo: number;
  prezzo_scontato: number;
  codice_wizard: string;
  qt?: number;
};

export type WizardInjection = {
  readonly webServers: typeof webServers;
  readonly cockpitOperativeSystems: typeof cockpitOperativeSystems;
  readonly paths: Paths;
  readonly codiceProdottoDominio: string;
  readonly codiciProdottiAddon: typeof codiciProdottiAddon;
  readonly codiciProdottiMail: typeof codiciProdottiMail;
  readonly codiceProdottoImunify: string;
  readonly dominioRegistrabile: boolean;
  readonly askClienti: boolean;
  readonly customers: MyCustomersAccess[];
  readonly customer: MyCustomersAccess | undefined;
  readonly askStack: boolean;
  readonly askAddon: boolean;
  readonly askAddonCpanel: boolean;
  readonly askMail: boolean;
  readonly askOs: boolean;
  readonly askEssentialOs: boolean;
  readonly askCockpitConfig: boolean;
  readonly askHardening: boolean;
  readonly askFarm: boolean;
  readonly askFarmBackup: boolean;
  readonly askProdotti: boolean;
  readonly askStorageQt: boolean;
  readonly askRegistrazione: boolean;
  readonly askDurataFatturazione: boolean;
  readonly askTestingMode: boolean;
  readonly hidePlusVat: boolean;
  readonly opzioniFarm: OpzioniFarmProduzione & typeof opzioniFarmMondo;
  readonly opzioniFarmMondo: typeof opzioniFarmMondo;
  readonly opzioniFarmProduzione: OpzioniFarmProduzione;
  readonly opzioniFarmBackup: typeof opzioniFarmBackup;
  readonly registraDominio: boolean;
  readonly codiceProdottoSla: string;
  readonly codiceProdottoStack: string | undefined;
  readonly availableDomain: boolean | null | undefined;
  readonly searchedDomain: string | null | undefined;
  readonly freePeriod: number;
  readonly groupedProducts: GroupedProducts;
  readonly productsCount: number;
  readonly defaultTitle: string;
  readonly canConfirmOffer: boolean;
  readonly durata: Durata;
  currentStep: string;
  opzioniHardening: typeof opzioniHardening;
  path: keyof Paths | undefined;
  sceltaFarmMondo: keyof typeof opzioniFarmMondo | undefined;
  sceltaFarmProduzione: keyof OpzioniFarmProduzione | undefined;
  sceltaFarmBackup: keyof typeof opzioniFarmBackup | undefined;
  farm: keyof OpzioniFarmProduzione | undefined;
  fatturaMe: boolean;
  addonSla: boolean;
  addonWebAccelerator: boolean;
  addonLitespeed: boolean;
  addonImunify: boolean;
  addonTerminalServer: boolean;
  domainAction: 'registrazione' | 'dns' | undefined;
  registrazionePremium: boolean;
  idClienteFinale: number | undefined;
  codiceSsl: string;
  codiceProdottoMail: string;
  stack: OpzioniStack | undefined;
  os: OperativeSystems;
  essentialOs: string | undefined;
  cockpitOs: string | undefined;
  cockpitWs: typeof webServers[number] | undefined;
  sceltaProdottoInfrastruttura: string;
  storageQt: number;
  square: boolean;
  codiceProdottoNextcloud: string;
  nomeDominio: string;
  fatturazioneAnnuale: boolean;
  renewAfterFreePeriod: boolean;
  hardening: (typeof opzioniHardening[number])[];
  action: OpzioniAction | undefined;
  offertaNr: string | number | null;
  title: string | undefined;
  note: string | undefined;
  sending: boolean;
  getProdotto: (codici: string) => Product;
  getProdotti: (codici: string[]) => Product[];
  tldRegistrabile: (domain: string) => boolean;
  totaleByDurata: (durata: Durata, scontato: boolean) => number;
  minPrice: (codici: string[]) => Product;
  goToStep: (step: string) => void;
  submit: (action: OpzioniAction) => Promise<void>;
  nextStep: () => void;
  previousStep: () => void;
  resetWizard: (dontAsk?: boolean) => void;
  opzioniProdottiInfrastruttura: () => string[];
  asyncComputed: {
    customers: {
      readonly updating: boolean;
      update: () => void;
    }
  };
};

export const WizardInjectionKey = Symbol('WizardInjectionKey') as InjectionKey<WizardInjection>;

export default defineComponent({
  name: 'Wizard',

  components: {
    WizardStepScopo,
    WizardStepFarm,
    WizardStepProdottiInfrastruttura,
    WizardStepStack,
    WizardStepHardening,
    WizardStepDominio,
    WizardStepAddon,
    WizardStepMail,
    WizardStepClienti,
    WizardStepSummary,
    WizardStepThankyou,
  },

  provide() {
    return {
      [WizardInjectionKey as symbol]: this,
    };
  },

  props: {
    freePeriod: {
      type: Number,
      default: 0,
    },
    searchedDomain: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    availableDomain: {
      type: Boolean as PropType<boolean | null | undefined>,
      default: null,
    },
  },

  setup(props) {
    const login = useLoginStore();
    const steps = provideChildrenTracker(WizardStepsTrackerSymbol);

    const initialState: Pick<WizardInjection,
        'currentStep'
      | 'path'
      | 'hardening'
      | 'sceltaFarmMondo'
      | 'sceltaFarmProduzione'
      | 'sceltaFarmBackup'
      | 'os'
      | 'stack'
      | 'essentialOs'
      | 'cockpitOs'
      | 'cockpitWs'
      | 'sceltaProdottoInfrastruttura'
      | 'storageQt'
      | 'square'
      | 'domainAction'
      | 'nomeDominio'
      | 'registrazionePremium'
      | 'codiceProdottoMail'
      | 'addonWebAccelerator'
      | 'addonLitespeed'
      | 'addonImunify'
      | 'addonTerminalServer'
      | 'codiceProdottoStack'
      | 'fatturaMe'
      | 'idClienteFinale'
      | 'renewAfterFreePeriod'
      | 'fatturazioneAnnuale'
      | 'action'
      | 'codiceSsl'
      | 'title'
      | 'note'
      | 'offertaNr'
    > = {
      currentStep: 'WizardStepScopo',
      path: undefined,
      hardening: [],
      sceltaFarmMondo: undefined,
      sceltaFarmProduzione: undefined,
      sceltaFarmBackup: undefined,
      stack: undefined,
      os: 'linux',
      essentialOs: undefined,
      cockpitOs: undefined,
      cockpitWs: undefined,
      sceltaProdottoInfrastruttura: 'HOSMN',
      storageQt: 1,
      square: false,
      domainAction: undefined,
      nomeDominio: props.searchedDomain ?? '',
      registrazionePremium: false,
      codiceProdottoMail: '',
      addonWebAccelerator: false,
      addonLitespeed: false,
      addonImunify: false,
      addonTerminalServer: false,
      codiceProdottoStack: undefined,
      fatturaMe: true,
      idClienteFinale: undefined,
      fatturazioneAnnuale: true,
      renewAfterFreePeriod: true,
      action: undefined,
      codiceSsl: '',
      title: undefined,
      note: undefined,
      offertaNr: null,
    };

    const { state: resettableState, reset: setDefault } = useResettableState(initialState);

    return {
      login,
      steps,
      ...resettableState,
      setDefault,
      ...setupAsyncComputed({
        products: {
          get(this: any) {
            return new CatalogResource({id_azienda: Number(login.id_azienda)}).get();
          },
          default: [] as CatalogProduct[],
        },

        customers: {
          async get(this: any) {
            if (!this.fatturaMe) {
              return await new MyCustomersResource().get();
            }
            return [];
          },
          default: [] as MyCustomersAccess[],
        },
      }),
    };
  },

  data() {
    return {
      sending: false,

      webServers,
      cockpitOperativeSystems,
      paths,
      opzioniFarmBackup,
      opzioniFarmMondo,
      codiciProdottiMail,
      codiciProdottiAddon,
      opzioniHardening,
    };
  },

  computed: {
    ready() {
      return this.asyncComputed.products.state === 'success';
    },

    opzioniFarmProduzione(): OpzioniFarmProduzione {
      const farm: OpzioniFarmProduzione = { ...farmProduzione };

      if (this.path === 'cloud') {
        farm.altro = $t('Altro');
      }

      return farm;
    },

    opzioniFarm(): OpzioniFarmProduzione & typeof opzioniFarmMondo {
      return {
        ...this.opzioniFarmProduzione,
        ...opzioniFarmMondo,
      };
    },

    durata(): Durata {
      return this.fatturazioneAnnuale ? 'Annuale' : 'Mensile';
    },

    askOs() {
      return (this.path === 'hosting');
    },

    askEssentialOs() {
      return this.askStack && this.stack === 'essential';
    },

    askCockpitConfig() {
      return this.askStack && this.stack === 'cockpit';
    },

    askHardening() {
      return (this.path === 'hosting' || this.path === 'cloud');
    },

    askFarm() {
      return this.path !== 'dominio' && this.path !== 'altro';
    },

    askFarmBackup() {
      return this.path !== 'dominio'  && this.path !== 'altro' && this.path !== 'storage';
    },

    askProdotti() {
      return this.path !== 'dominio'  && this.path !== 'altro' && this.path !== 'mail';
    },

    askStorageQt() {
      return this.path === 'storage';
    },

    askRegistrazione() {
      return !['dominio', 'storage'].includes(this.path ?? '');
    },

    askAddon() {
      return this.path !== 'dominio';
    },

    askAddonCpanel() {
      return this.path === 'cloud' && this.stack === 'whm';
    },

    askStack() {
      return this.path === 'cloud';
    },

    askTestingMode() {
      return this.freePeriod > 0;
    },

    hidePlusVat() {
      if (this.fatturaMe && this.login.iva < 0.01) {
        return true;
      }
      if (this.customer && this.customer.vat < 0.01) {
        return true;
      }

      return false;
    },

    askMail() {
      return (this.path === 'cloud' && this.stack !== 'whm' && this.stack !== 'plesk linux' && this.stack !== 'plesk windows') || this.path === 'mail' || (this.path === 'hosting' && this.os === 'windows');
    },

    askClienti() {
      return this.login.is_reseller;
    },

    askDurataFatturazione() {
      return this.path !== 'dominio' && this.path !== 'mail';
    },

    registraDominio() {
      return ((this.searchedDomain && this.searchedDomain === this.nomeDominio ) || ((this.path === 'dominio' || this.domainAction === 'registrazione'))) && !!this.nomeDominio;
    },

    estensioneDominio() {
      const tld = this.tldRegistrabile(this.nomeDominio);
      if (tld !== false) {
        return tld;
      }

      const v = this.nomeDominio.split('.');
      return v[v.length - 1];
    },

    tldRegistrabili() {
      const tld: Record<string, string> = {};
      const domini = this.products.filter(p => p.tld);
      for (const dom of domini) {
        if (dom.tld) {
          tld[dom.tld] = dom.codice;
        }
      }
      return tld;
    },

    dominioRegistrabile() {
      return this.tldRegistrabile(this.nomeDominio) !== false;
    },

    codiceProdottoDominio() {
      return this.tldRegistrabili[this.estensioneDominio];
    },

    codiceProdottoImunify() {
      switch (this.codiceProdottoStack) {
        case 'AHSWHM1': return 'AHSIM3601';
          break;
        case 'AHSWHM5': return 'AHSIM36030';
        case 'AHSWHM30': return 'AHSIM36030';
          break;
        case 'AHSWHM100': return 'AHSIM360250';
        case 'AHSWHM250': return 'AHSIM360250';
          break;
        case 'AHSWHM500': return 'AHSIM360500';
        default: return 'AHSIM3601';
      };
    },

    farm() {
      return this.sceltaFarmProduzione === 'altro' ? this.sceltaFarmMondo : this.sceltaFarmProduzione;
    },

    customer(): MyCustomersAccess | undefined {
      if (!this.fatturaMe && this.idClienteFinale) {
        return this.customers.find(c => c.id == this.idClienteFinale);
      }

      return {
        id: Number(this.login.id_azienda),
        ragione_sociale: this.login.azienda,
        currency: this.login.currency,
        currency_locked: this.login.currency_locked,
        vat: this.login.iva,
        enable_orders: false,
        indirizzo: null,
        provincia: null,
        cap: null,
        citta: null,
        stato: 'CH',
        presented: false,
        partita_iva: null,
        contact_name: null,
        contact_mail: null,
      };
    },

    canConfirmOffer() {
      return this.fatturaMe ? this.login.enable_orders : this.customer?.enable_orders ?? false;
    },

    defaultTitle() {
      return $t('Offerta per servizi internet - {0}', [this.nomeDominio]);
    },

    productsCount() {
      let c = 0;
      for (const i of Object.values(this.groupedProducts)) {
        c += i.items.length;
      }
      return c;
    },

    groupedProducts() {
      const prodotti: GroupedProducts = {
        dominio: {
          items: [],
        },
        infrastruttura: {
          items: [],
        },
        addon: {
          items: [],
        },
        mail: {
          items: [],
        },
      };

      // Prodotti dominio

      if (this.registraDominio && this.dominioRegistrabile) {
        prodotti.dominio.items.push(this.getProdotto(this.codiceProdottoDominio));

        if (this.registrazionePremium) {
          prodotti.dominio.items.push(this.getProdotto(this.codiciProdottiAddon.registrazionePremium));
        }
      } else if (this.domainAction === 'dns') {
        prodotti.dominio.items.push(this.getProdotto(this.codiciProdottiAddon.soloDNS));
      }

      // Prodotti infrastruttura
      if (this.askProdotti && this.sceltaProdottoInfrastruttura) {
        prodotti.infrastruttura.items.push({
          ...this.getProdotto(this.sceltaProdottoInfrastruttura),
          qt: this.askStorageQt ? this.storageQt : 1,
        });
      }

      //Stack
      if (this.askStack && this.codiceProdottoStack) {
        prodotti.addon.items.push(this.getProdotto(this.codiceProdottoStack));
      }

      // Prodotti AddOn
      if (this.askAddon) {
        if (this.codiceSsl) {
          prodotti.addon.items.push(this.getProdotto(this.codiceSsl)); // Prodotto unicamente annuale
        }
        if (this.askAddonCpanel) {
          if (this.addonImunify && this.codiceProdottoImunify) {
            prodotti.addon.items.push(this.getProdotto(this.codiceProdottoImunify));
          }
          if (this.addonLitespeed) {
            prodotti.addon.items.push(this.getProdotto(this.codiciProdottiAddon.liteSpeed));
          }
        }

        if (this.path === 'altro' && this.addonTerminalServer) {
          prodotti.addon.items.push(this.getProdotto(this.codiciProdottiAddon.terminalServer));
        }
      }

      // Prodotti mail
      if (this.askMail && this.codiceProdottoMail) {
        prodotti.mail.items.push(this.getProdotto(this.codiceProdottoMail));
      }

      return prodotti;
    },
  },

  watch: {
    fatturaMe(v) {
      if (v) {
        this.idClienteFinale = undefined;
      }
    },

    opzioniFarmProduzione(v) {
      if (!('altro' in v) && this.sceltaFarmProduzione === 'altro') {
        this.sceltaFarmProduzione = undefined;
      }
    },

    sceltaFarmProduzione(v) {
      if (v && this.sceltaFarmBackup === v) {
        this.sceltaFarmBackup = undefined;
      }
    },

    stack(v: OpzioniStack) {
      if (this.askStack && v) {
        if (codiciProdottiAddon.stack[v].codici.length === 1) {
          this.codiceProdottoStack = codiciProdottiAddon.stack[v].codici[0];
        } else {
          this.codiceProdottoStack = undefined;
        }
      } else {
        this.codiceProdottoStack = undefined;
      }
    },

  },

  mounted() {
    this.setDefault();
  },

  methods: {
    opzioniProdottiInfrastruttura() {
      if (!this.path || this.path === 'dominio' || this.path === 'mail' || this.path === 'altro') {
        return [];
      }

      if (this.path === 'storage' || this.path === 'cloud') {
        return codiciProdottiInfrastruttura[this.path]['linux'];
      }

      if (this.path === 'hosting' && this.square && this.os === 'linux') {
        return codiciProdottiInfrastruttura[this.path][this.os].map(codice => `${codice}2`);
      }

      return codiciProdottiInfrastruttura[this.path][this.os];
    },

    tldRegistrabile(domain: string) {
      for (const tld of Object.keys(this.tldRegistrabili).sort(by(s => s.length, true))) {
        if (domain.endsWith(`.${tld}`)) {
          return tld;
        }
      }
      return false;
    },

    nextStep() {
      for (
        let i = this.steps.findIndex(x => x.$options.name === this.currentStep) + 1;
        i < this.steps.length;
        i++
      ) {
        const step = this.steps[i];
        const enabled = step.isEnabled ?? toValue(step.$.exposed?.isEnabled);
        if (enabled && step.$options.name) {
          this.currentStep = step.$options.name;
          return;
        }
      }
    },

    previousStep() {
      for (let i = this.steps.findIndex(x => x.$options.name === this.currentStep) - 1; i >= 0; i--) {
        const step = this.steps[i];
        const enabled = step.isEnabled ?? toValue(step.$.exposed?.isEnabled);
        if (enabled && step.$options.name) {
          this.currentStep = step.$options.name;
          return;
        }
      }
    },

    resetWizard(dontAsk: boolean) {
      const r = dontAsk || confirm($t('Sei sicuro di voler resettare tutte le tue scelte e tornare al primo step?'));
      if (r) {
        this.setDefault();
      }
    },

    getProdotto(codice: string) {
      if (this.products) {
        const prodotto = this.products.find(
          p => (p.group_code === codice && p.durata === this.durata) || p.codice === codice,
        ) as Product;
        if (prodotto) {
          prodotto.prezzo = this.customer?.currency === 'EUR' ? prodotto.prezzo_eur : prodotto.prezzo_chf;
          prodotto.prezzo_scontato =
            this.customer?.currency === 'EUR' ? prodotto.prezzo_scontato_eur : prodotto.prezzo_scontato_chf;
          prodotto.codice_wizard = codice;
          return prodotto;
        }
      }
      return { codice } as Product;
    },

    getProdotti(codici: string[]) {
      const prodotti = [];
      for (const codice of codici) {
        prodotti.push(this.getProdotto(codice));
      }
      return prodotti;
    },

    minPrice(codici: string[]) {
      const prodotti = this.getProdotti(codici);
      return prodotti.reduce((a, b) => a.prezzo_scontato < b.prezzo_scontato ? a : b);
    },

    totaleByDurata(durata: Durata, scontato: boolean) {
      return Object.values(this.groupedProducts)
        .map(c => c.items)
        .flat()
        .filter(p => p.durata === durata)
        .reduce((t, p) => t + ((this.fatturaMe && scontato ? p.prezzo_scontato : p.prezzo) * (p.qt ?? 1)), 0);
    },

    goToStep(step: string) {
      this.currentStep = step;
    },

    createOffer(action: OpzioniAction) {
      if (!this.login.id_azienda || !this.customer) {
        return;
      }

      let id_cliente;
      if (!this.fatturaMe) {
        if (!this.idClienteFinale) {
          throw new Error('Nessun cliente finale selezionato');
        }
        id_cliente = this.idClienteFinale;
      } else {
        id_cliente = this.login.id_azienda;
      }

      const data: Partial<Offer> & InfoWizard = {
        action: action,
        currency: this.customer.currency,
        nomeDominio: this.nomeDominio,
        title: this.title ?? this.defaultTitle,
        free_period: this.freePeriod,
        id_azienda: Number(id_cliente),
        prodotti: [],
      };

      if (this.askTestingMode) {
        data.testing_mode = this.renewAfterFreePeriod ? 'rinnova' : 'disdici';
      }

      if (this.askHardening) {
        data.hardening = this.hardening.length > 0 ? this.hardening : ['Nessuno'];
      }

      if (this.askEssentialOs) {
        data.os = this.essentialOs;
      } else if (this.askCockpitConfig && this.cockpitWs) {
        data.os = this.cockpitOs;
        data.webserver = this.cockpitWs;
      } else if (this.askOs) {
        data.os = this.os;
      }

      if (this.askFarm && this.farm) {
        data.farm = this.opzioniFarm[this.farm];
      }

      if (this.askFarmBackup && this.sceltaFarmBackup) {
        data.farmBackup = this.opzioniFarmBackup[this.sceltaFarmBackup];
      }

      if (!this.fatturaMe) {
        data.id_reseller = Number(this.login.id_azienda);
      }

      if (this.note) {
        data.note = this.note;
      }

      for (const prodotto of Object.values(this.groupedProducts)
        .map(c => c.items)
        .flat()) {
        data.prodotti.push({
          codice: prodotto.codice,
          qt: prodotto.qt ?? 1,
        });
      }

      const offer = new OffersResource();
      return offer.save(data);
    },

    async submit(action: OpzioniAction) {
      this.action = action;

      if (this.sending || this.productsCount === 0) {
        return;
      }
      this.sending = true;

      try {
        const response = await this.createOffer(action);
        this.offertaNr = response.id_offerta;

        if (this.fatturaMe) {
          const company = useCompanyStore();
          if (company.id) {
            company.refresh();
          } else {
            this.login.refresh();
          }
        }

        if (action == 'confirm') {
          this.$router.push({
            name: 'confirm_offer',
            params: { id: response.id_offerta },
          });
        } else {
          this.nextStep();
        }
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>
