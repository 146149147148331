<template>
  <wizard-form v-if="!hidden" v-bind="ouiaProps" next-label="" confirm :title="$t('Riepilogo e preventivo')">
    <div class="question-wrapper">
      <wizard-summary-goto />
    </div>
    <template v-if="wizard.productsCount === 0">
      <h3>{{ $t('Nessun prodotto trovato') }}</h3>
      <p>{{ $t('Con le opzioni da te selezionate non ci è al momento possibile generare un preventivo.') }}</p>
      <p>
        <i18n-t
          keypath="Puoi provare a modificare le opzioni scelte oppure contattare il nostro supporto commerciale {0}."
          tag="span"
        >
          <router-link :to="{ name: 'tickets' }">
            {{ $t('aprendo un ticket') }}
          </router-link>
        </i18n-t>
      </p>
    </template>

    <template v-else>
      <div v-if="wizard.askDurataFatturazione || !wizard.customer?.currency_locked" class="question-wrapper">
        <h3>{{ $t('Opzioni di fatturazione') }}</h3>

        <div class="fatturazione-wrapper">
          <div v-if="wizard.askDurataFatturazione">
            {{ $t('Ricorrenza ') }}:
            <toggle
              v-model="wizard.fatturazioneAnnuale"
              :on-value="true"
              :off-value="false"
              :on-text="$t('Annuale')"
              :off-text="$t('Mensile')"
              off-class="btn-success"
            />
          </div>
          <div v-if="wizard.fatturaMe && !wizard.customer?.currency_locked">
            {{ $t('Valuta') }}:
            <currency-toggle id="currency-dropdown" />
          </div>
        </div>
      </div>

      <div class="question-wrapper">
        <h3>
          {{ $t('Ecco i prodotti che ti consigliamo') }}
        </h3>
        <div v-if="wizard.groupedProducts.dominio.items.length > 0">
          <h3>{{ $t('Registrazione dominio') }}</h3>
          <product-list
            :items="wizard.groupedProducts.dominio.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
        <div v-if="wizard.groupedProducts.infrastruttura.items.length > 0">
          <h3>{{ $t('Infrastruttura') }}</h3>
          <product-list
            :items="wizard.groupedProducts.infrastruttura.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
            :show-qt="wizard.askStorageQt"
          />
        </div>
        <div v-if="wizard.groupedProducts.addon.items.length > 0">
          <h3>{{ $t('Servizi Aggiuntivi') }}</h3>
          <product-list
            :items="wizard.groupedProducts.addon.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
        <div v-if="wizard.groupedProducts.mail.items.length > 0">
          <h3>{{ $t('Email') }}</h3>
          <product-list
            :items="wizard.groupedProducts.mail.items"
            :currency="wizard.customer?.currency ?? 'EUR'"
            :always-full-price="!wizard.fatturaMe"
            :hide-plus-vat="wizard.hidePlusVat"
            :hide-price="wizard.freePeriod > 0"
          />
        </div>
      </div>
      <div>
        <h3>{{ $t('Titolo') }}</h3>
        <p>{{ $t('Se vuoi puoi dare un titolo all\'offerta:') }}</p>
        <input
          v-model="wizard.title"
          type="text"
          name="title"
          class="note-finali"
          :placeholder="wizard.defaultTitle"
        >
      </div>
      <div>
        <h3>{{ $t('Note') }}</h3>
        <p>{{ $t('Scrivici ogni tipo di nota o chiarimento relativo al progetto') }}</p>
        <textarea
          v-model="wizard.note"
          name="note"
          placeholder="Note Progetto"
          class="note-finali"
          rows="10"
        />
      </div>
      <div id="totali" class="question-wrapper">
        <wizard-summary-totals v-if="!wizard.freePeriod" durata="Annuale" />
        <wizard-summary-totals v-if="!wizard.freePeriod" durata="Mensile" />
        <div v-if="wizard.askTestingMode">
          <strong class="free-label">{{ $t('I primi {0} giorni sono gratuiti', [wizard.freePeriod]) }}</strong>
          <div>
            <toggle
              v-model="wizard.renewAfterFreePeriod"
              :on-value="true"
              :off-value="false"
              :on-text="$t('Rinnova dopo il periodo gratuito')"
              :off-text="$t('Disdici dopo il periodo gratuito')"
            />
          </div>
        </div>

        <wizard-summary-actions />
      </div>
    </template>
  </wizard-form>
</template>

<style lang="scss">
.riepilogo-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.riepilogo-wrapper ul {
  padding: 0;
  list-style-position: inside;
}
.btn-link.modifica {
  color: #000;
  padding: 0;
}
.fatturazione-wrapper > div {
  margin-bottom: 10px;
}
.radio-action{
  text-align: right;
}
.note-finali {
  width: 500px;
}
@media (min-width: $screen-lg-min) {
  .riepilogo-wrapper > div {
    width: 25%;
  }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .riepilogo-wrapper > div {
    width: 50%;
  }
}
@media (max-width: $screen-xs-max) {
  .riepilogo-wrapper,
  .fatturazione-wrapper {
    flex-direction: column;
  }
  .fatturazione-wrapper > div {
    margin-bottom: 20px;
  }
}
@media (min-width: $screen-sm-min) {
  .fatturazione-wrapper > div {
    width: 50%;
  }
}

#totali {
  font-size: 16px;
  text-align: right;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import CurrencyToggle from '../../currency-toggle.vue';
import ProductList from '../../product-list.vue';
import WizardSummaryTotals from '../wizard-summary-totals.vue';
import WizardSummaryGoto from '../wizard-summary-goto.vue';
import WizardSummaryActions from '../wizard-summary-actions.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepSummary',

  components: {
    Toggle,
    ProductList,
    CurrencyToggle,
    WizardSummaryTotals,
    WizardSummaryGoto,
    WizardSummaryActions,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep(),
    };
  },
});
</script>
