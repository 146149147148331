<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :next-label="''" :title="$t('Wizard completato')">
    <div style="text-align: center">
      <p v-if="wizard.action == 'mailme'">
        {{ $t('Controlla la tua mail, ti abbiamo inviato il riepilogo di tutte le opzioni da te scelte ed il relativo preventivo PDF.') }}
      </p>

      <p v-if="wizard.action == 'mailcustomer'">
        {{ $t('Abbiamo inviato il preventivo alla mail del cliente da te scelto.') }}
      </p>

      <p v-if="wizard.offertaNr">
        <a target="_blank" :href="`/rest/offer-pdf/${wizard.offertaNr}`">{{
          $t("Clicca qui per scaricare l'offerta")
        }}</a>
      </p>
    </div>

    <template #actions>
      <router-link :to="{ name: 'dashboard' }">
        {{ $t('Vai alla dashboard') }}
      </router-link>

      <router-link :to="{ name: 'offers' }">
        {{ $t('Vai alle offerte') }}
      </router-link>

      <pf-button class="btn-cancel" variant="primary" @click="wizard.resetWizard(true)">
        {{ $t('Comincia un nuovo wizard') }}
      </pf-button>
    </template>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepThankYou',

  components: {
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep(),
    };
  },
});
</script>
