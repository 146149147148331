/// <reference types="vite/client" />

import type { App } from "vue";
import type { RouterLink, RouterView, TypesConfig } from "vue-router";
import type { DatetimeFormat, NumberFormat, Translation } from "vue-i18n";
import { formatCurrency, formatNumber, formatSize, pop } from '@common/utils';
import { parseDateTime } from "@common/date";
import WithVar from '@common/WithVar.vue';

export const $date = (value: string) => parseDateTime(value).toLocaleString({ year: 'numeric', month: 'long', day: '2-digit' });
export const $datetime = (value: string) => parseDateTime(value).toLocaleString({ year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' });
export const $datefromnow = (value: string) => parseDateTime(value).toRelative();
export const $currency = (value: string | number, currency?: string) => currency ? formatCurrency(value, currency) : formatNumber(value);
export const $size = formatSize;
export const $int = (value: string | number) => Number(value).toFixed();
export const $percent = (value: string | number) => `${(Number(value) * 100).toFixed()}%`;

export function install(app: App) {
  app.component('WithVar', WithVar);

  app.mixin({
    methods: {
      $date,
      $datetime,
      $datefromnow,
      $currency,
      $size,
      $int,
      $percent,
      $pop: pop,
    },
  });
}

declare module 'vue' {
  export interface GlobalComponents {
    WithVar: typeof WithVar;

    // temp fix for vue-router wrong declare module
    RouterView: TypesConfig extends Record<'RouterView', infer T>
      ? T
      : typeof RouterView
    RouterLink: TypesConfig extends Record<'RouterLink', infer T>
      ? T
      : typeof RouterLink

    // temp fix for vue-i18n wrong declare module
    ['i18n-t']: typeof Translation
    ['i18n-d']: typeof DatetimeFormat
    ['i18n-n']: typeof NumberFormat
    ['I18nT']: typeof Translation
    ['I18nD']: typeof DatetimeFormat
    ['I18nN']: typeof NumberFormat
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $date: typeof $date;
    $datetime: typeof $datetime;
    $datefromnow: typeof $datefromnow;
    $currency: typeof $currency;
    $size: typeof $size;
    $int: typeof $int;
    $percent: typeof $percent;
    $pop: typeof pop;
    // $root: AdminData;
  }
}

declare module '@vue/runtime-dom' {
  export interface HTMLAttributes {
    // allow any data-* attr
    [key: `data${string}`]: boolean | string | number | undefined | null;
  }
}

export default { install };
