<template>
  <label
    v-bind="ouiaProps"
    class="btn"
    :class="[
      stateClass,
      {
        'btn-default': activeClass == 'active' && inactiveClass != 'btn-default',
        disabled,
      },
    ]"
    @click="btnChange"
  >
    <input
      v-if="input && input !== 'none'"
      :type="input"
      tabindex="-1"
      class="input-hidden"
      :name="name"
      :checked="checked"
      :required="required || undefined"
      :disabled="disabled || undefined"
      :value="checkedValue"
      @change="change"
    >
    <slot />
  </label>
</template>

<style scoped>
label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';

export default defineComponent({
  name: 'BtnRadio',

  props: {
    activeClass: {
      type: String,
      default: 'active',
    },
    inactiveClass: String,
    modelValue: {
      type: [Boolean, Number, String] as PropType<boolean | number | string | null>,
      default: null,
    },
    name: String,
    checkedValue: {
      type: [Boolean, Number, String],
      default: true,
    },
    input: {
      type: String,
      default: 'radio',
    },
    required: Boolean,
    disabled: Boolean,
    ...ouiaProps,
  },

  emits: ['update:modelValue'],

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
    };
  },

  computed: {
    checked() {
      return this.checkedValue == this.modelValue;
    },

    stateClass() {
      if (this.checked && this.activeClass) {
        return this.activeClass;
      }
      if (!this.checked && this.inactiveClass) {
        return this.inactiveClass;
      }
      return '';
    },
  },

  methods: {
    change(e: Event) {
      if (e.target instanceof HTMLInputElement) {
        // re-enforce the checkbox value from the prop to undo the native toggle
        e.target.checked = this.checked;
      }
      if (!this.checked) {
        this.$emit('update:modelValue', this.checkedValue);
      }
    },

    btnChange(e: Event) {
      if ((!this.input || this.input === 'none') && !this.checked) {
        this.$emit('update:modelValue', false);
      }
    },
  },
});
</script>
