<template>
  <div class="list-group list-view-pf list-view-pf-view list-view-pf-stacked bordered">
    <pf-list-group-item v-for="row in projects" :key="row.nome">
      <pf-list-item>
        <template #heading>
          <div>{{ row.nome }}</div>
          <div class="project-date">{{ $date(row.data) }}</div>
        </template>

        <template #additional-info>
          <pf-list-item-additional-info v-if="!projectWithNoDetails(row)" expandable>
            {{ $t('Dettaglio progetto') }}
            <template #expansion>
              <div class="row project-details">
                <div class="col-md-6">
                  <div v-if="row.descrizione"><strong>{{ _t('Descrizione') }}</strong>: {{ row.descrizione }}</div>
                  <div v-if="row.dominio"><strong>{{ _t('Dominio') }}</strong>: {{ row.dominio }}</div>
                  <div v-if="row.tempistiche"><strong>{{ _t('Tempistiche') }}</strong>: {{ row.tempistiche }}</div>
                  <div v-if="row.budget"><strong>{{ _t('Budget') }}</strong>: {{ row.budget }}</div>

                  <template v-if="isOpportunity(row)">
                    <template v-if="row.stato === 'accettata'">
                      <div v-if="row.cliente"><strong>{{ _t('Cliente') }}</strong>: {{ row.cliente }}</div>
                      <span v-if="row.contatti" class="contatti">{{ row.contatti }}</span>
                    </template>
                    <div v-else><i>{{ _t('I dati del richiedente vengono mostrati solo per le opportunità accettate.') }}</i></div>
                  </template>
                  <template v-else>
                    <template v-if="row.stato === 'accettata'">
                      <div v-if="row.partner">
                        <strong>{{ _t('Partner assegnato') }}</strong>: {{ row.partner }}
                      </div>
                    </template>
                    <div v-else><i>{{ _t('I dati del partner verranno mostrati una volta che il progetto verrà accettato.') }}</i></div>
                  </template>

                </div>
                <div class="col-md-6">
                  <div v-if="row.skills.length" class="col-lg-4">
                    <strong>{{ _t('Skill') }}:</strong>
                    <ul>
                      <li v-for="s in row.skills" :key="s">{{ s }}</li>
                    </ul>
                  </div>
                  <div v-if="row.tech_skills.length" class="col-lg-4">
                    <strong>{{ _t('Tecnologie') }}:</strong>
                    <ul>
                      <li v-for="ts in row.tech_skills" :key="ts">{{ ts }}</li>
                    </ul>
                  </div>
                  <div v-if="row.business_sectors.length" class="col-lg-4">
                    <strong>{{ _t('Settori merceologici') }}:</strong>
                    <ul>
                      <li v-for="bs in row.business_sectors" :key="bs">{{ bs }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </pf-list-item-additional-info>
        </template>
      </pf-list-item>

      <template #action>
        <template v-if="isOpportunity(row) && row.stato === 'attesa'">
          <pf-button variant="danger" @click="feedback(row.id, false)">
            {{ $t('Rifiuta opportunità') }}
          </pf-button>
          <pf-button variant="success" class="btn btn-success" @click="feedback(row.id, true)">
            {{ $t('Accetta opportunità') }}
          </pf-button>
        </template>
        <div
          v-else
          class="label stato"
          :class="{
            'label-success': row.stato === 'accettata',
            'label-danger': row.stato === 'rifiutata',
            'label-default': row.stato === 'scaduta' || row.stato === 'attesa' || row.stato === 'chiusa',
          }">
            {{ row.stato }}
            <form-help-tooltip v-if="row.stato === 'scaduta'" :text="_t('Un\'opportunità scade se è passato troppo tempo dalla richiesta o se è già stata accettata')" />
        </div>
      </template>
    </pf-list-group-item>
  </div>
</template>

<style lang="scss" scoped>
.project-date {
  font-size: 0.7em;
  font-weight: 400;
}
.project-details div {
  margin-top: 10px;

  &:first-child {
    margin-top: 0svb
  }
}
.contatti {
  white-space: pre-line;
}
.stato {
  font-size: 15px;
  text-transform: capitalize;
}
</style>

<script lang="ts" setup generic="Variant extends 'project' | 'opportunity'">
import { $t as _t } from '@/i18n';
import { type Opportunity, type Project, OpportunityResource } from '../resources';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

const props = defineProps<{
  variant: Variant;
  projects: (Variant extends 'project' ? Project : Opportunity)[];
}>();

const emit = defineEmits<{
  (e: 'feedback'): void
}>();

function isOpportunity(project: Project| Opportunity): project is Opportunity {
  return props.variant === 'opportunity';
}

// E' possibile creare dalla intranet opportunità senza dettagli
function projectWithNoDetails(project: Project| Opportunity) {
  return !project.descrizione && !project.budget && !project.tempistiche && !project.skills.length && !project.tech_skills.length && !project.business_sectors.length;
}

async function feedback(id: number, accept: boolean) {
  const confirmMessage = accept ? _t("Confermi di voler accettare l'opportunità? Ti daremo accesso ai dati del cliente per poterlo contattare direttamente") : _t("Stai rifiutando l'opportunità, confermi di voler procedere?");

  if (!confirm(confirmMessage)) {
    return;
  }

  await new OpportunityResource().put(id, {
    stato: accept ? 'accettata' : 'rifiutata',
  });

  emit('feedback');
}
</script>
