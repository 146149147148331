<template>
  <iframe
    ref="iframe"
    :style="{ height: height === 'auto' ? '26px' : `${height}px`, minHeight }"
    @load="loaded"
  />
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, watch, type Ref, ref, type IframeHTMLAttributes } from "vue";
import type { Property } from "csstype";

export interface Props extends /* @vue-ignore */ IframeHTMLAttributes {
  extraPadding?: string | number;
  maxHeight?: string | number;
  minHeight?: Property.MinHeight;
}

const props = withDefaults(defineProps<Props>(), {
  extraPadding: 0,
  maxHeight: 0,
});

let resizeObserver: ResizeObserver | null = null;
const iframe: Ref<HTMLIFrameElement | undefined> = ref();
const height: Ref<'auto' | number> = ref('auto');

const intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      resize();
      return;
    }
  }
});

watch(() => props.extraPadding, resize);

onMounted(() => {
  if (iframe.value) {
    intersectionObserver.observe(iframe.value);
  }
});

onBeforeUnmount(() => {
  intersectionObserver.disconnect();
  resizeObserver?.disconnect();
});


function loaded() {
  resizeObserver?.disconnect();

  if (!iframe.value) {
    return;
  }

  resizeObserver = new ResizeObserver(entries => {
    if (height.value !== 'auto' || !resizeObserver) {
      return;
    }

    for (const entry of entries) {
      if (entry.target === iframe.value) {
        resizeObserver.disconnect();
        resize();
        return;
      }
    }
  });
  resizeObserver.observe(iframe.value);

  resize();
}

function resize() {
  let newHeight = (iframe.value?.contentDocument?.body?.scrollHeight ?? 0) + Number(props.extraPadding);
  if (props.maxHeight) {
    newHeight = Math.min(typeof props.maxHeight === 'string' ? parseFloat(props.maxHeight) : props.maxHeight, newHeight);
  }
  height.value = newHeight;
}

defineExpose({
  iframe,
  resize,
});
</script>
