<template>
  <table>
    <tbody>
      <tr>
        <th class="grant-name">
          {{ $t('Permesso') }}
        </th>
        <th class="grant" :title="$t('Amministratore')">
          {{ $t('Admin') }}
        </th>
        <th class="grant" :title="$t('Amministrativi')">
          {{ $t('Amm.') }}
        </th>
        <th class="grant" :title="$t('Commerciali')">
          {{ $t('Comm.') }}
        </th>
        <th class="grant" :title="$t('Tecnici')">
          {{ $t('Tech.') }}
        </th>
      </tr>
      <tr v-for="grant in grants" :key="grant.name">
        <td class="grant-name">
          {{ grant.name }}
        </td>
        <td v-for="(allowed, index) in grant.grant" :key="index" class="grant">
          <template v-if="typeof allowed =='boolean'">
            <ok-icon v-if="allowed" />
            <circle-exclamation-icon v-else />
          </template>
          <template v-else>{{ allowed }}</template>
        </td>
      </tr>

      <template v-if="login.is_reseller">
        <tr v-for="grant in resellerGrants" :key="grant.name">
          <td class="grant-name">
            {{ grant.name }}
          </td>
          <td v-for="(allowed, index) in grant.grant" :key="index" class="grant">
            <template v-if="typeof allowed =='boolean'">
              <ok-icon v-if="allowed" />
              <circle-exclamation-icon v-else />
            </template>
            <template v-else>{{ allowed }}</template>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style scoped>
table {
  width: 100%;
  margin: 0px;

}
.grant-name {
  width: 40%;
  border-bottom: 1px solid #D0D0D0;
  padding: 5px 0;
}
.grant {
  width: 15%;
  text-align: center;
  border-bottom: 1px solid #D0D0D0;
  padding: 5px 0;
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { useLoginStore } from '@/store/login';
import { defineComponent } from 'vue';
import OkIcon from '@vue-patternfly/icons/ok-icon';
import CircleExclamationIcon from '@vue-patternfly/icons/circle-exclamation-icon';

interface Grant {
  name: string;
  grant: [boolean, boolean, boolean, boolean];
}

const grants: Grant[] = [
  {
    name: $t('Gestione degli utenti'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Visualizzazione e modifica dati aziendali'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Visualizzazione prodotti, prezzi, creazione delle offerte'),
    grant: [true, true, true, false],
  },
  {
    name: $t('Conferma di ordini'),
    grant: [true, true, true, false],
  },
  {
    name: $t('Conferma di upgrade'),
    grant: [true, true, true, false],
  },
  {
    name: $t('Rinnovi/Disdette di servizi'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Attivazione/Disattivazione di pagamenti ricorrenti'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Visualizzazione e pagamento fatture'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Visualizzazione delle password dei servizi'),
    grant: [true, false, false, true],
  },
  {
    name: $t('Modifica delle zone DNS'),
    grant: [true, false, false, true],
  },
  {
    name: $t('Apertura ticket'),
    grant: [true, true, true, true],
  },
];

const resellerGrants: Grant[] = [
  {
    name: $t('Visualizzazione clienti'),
    grant: [true, true, true, true],
  },
  {
    name: $t('Possibilità di richiedere accesso ai clienti'),
    grant: [true, true, true, true],
  },
  {
    name: $t('Creazione nuovo prospect'),
    grant: [true, true, true, false],
  },
  {
    name: $t('Visualizzazione credito Artera e gestione delle provvigioni'),
    grant: [true, true, false, false],
  },
  {
    name: $t('Aggiornare le skill'),
    grant: [true, true, false, false],
  },
];

export default defineComponent({
  name: 'UserLegend',

  components: {
    OkIcon,
    CircleExclamationIcon,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
    };
  },

  data(this: void) {
    return {
      grants,
      resellerGrants,
    };
  },
});
</script>
