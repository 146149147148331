 <template>
  <h4>{{ _t('Dati del registrante del dominio {0}', [domain.dominio]) }}</h4>
  <form role="form" method="post" @submit.prevent="send">
    <input type="hidden" name="id" :value="domainData.id">

    <div class="row">
      <div class="form-group col-lg-3 col-sm-6">
        <label for="domainAuthcode">
          {{ _t('Codice authcode') }}
          <form-help-tooltip
            :text="_t('Si tratta del codice che occorre per poter trasferire il dominio, se non lo hai devi richiederlo al tuo attuale provider che è obbligato a inviartelo.')"
          />
        </label>
        <div>
          <input
            id="domainAuthcode"
            v-model="domainData.authcode"
            type="text"
            name="authcode"
            class="form-control"
          >
        </div>
      </div>
    </div>

    <div v-if="login.complete" class="billingRadio">
      <div>
        <label>
          <input v-model="domainData.usa_dati_fatturazione" name="usa_dati_fatturazione" type="radio" :value="true" required>
          {{ _t(`Intesta il dominio a ${login.azienda}`) }}
        </label>
      </div>
      <div>
        <label>
          <input v-model="domainData.usa_dati_fatturazione" name="usa_dati_fatturazione" type="radio" :value="false" required>
          {{ _t('Oppure inserisci i dati del registrante') }}
        </label>
      </div>
    </div>

    <fieldset v-if="!domainData.usa_dati_fatturazione">
      <div class="form-group btn-group">
        <btn-radio v-model="domainData.tipologia" checked-value="Private" name="tipologia">
          {{ _t('Persona fisica') }}
        </btn-radio>
        <btn-radio v-model="domainData.tipologia" checked-value="Company" name="tipologia">
          {{ _t('Azienda') }}
        </btn-radio>
      </div>

      <div class="row">
        <div class="form-group col-lg-3 col-sm-6">
          <label for="domainName"> {{ _t('Nome') }} * </label>
          <div>
            <input
              id="domainName"
              v-model="domainData.nome"
              type="text"
              name="nome"
              class="form-control"
              required
            >
          </div>
        </div>

        <div class="form-group col-lg-3 col-sm-6">
          <label for="domainSurname"> {{ _t('Cognome') }} * </label>
          <div>
            <input
              id="domainSurname"
              v-model="domainData.cognome"
              type="text"
              name="cognome"
              class="form-control"
              required
            >
          </div>
        </div>
      </div>

      <div v-if="!personaFisica" class="row">
        <div class="form-group col-md-12 col-lg-6">
          <label for="domainDataRagioneSociale">{{ _t('Ragione Sociale') }} *</label>
          <input
            id="domainDataRagioneSociale"
            v-model="domainData.ragione_sociale"
            name="ragione_sociale"
            type="text"
            class="form-control"
            required
            maxlength="250"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-8 col-lg-4">
          <label for="domainDataNazione">{{ _t('Nazione') }} *</label>
          <select
            id="domainDataNazione"
            v-model="domainData.stato"
            name="stato"
            class="form-control"
            autocomplete="country"
          >
            <option v-for="country in countries" :key="country.iso" :value="country.iso">
              {{ country.country }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-4 col-lg-2">
          <label for="domainDataCAP">{{ _t('CAP') }} *</label>
          <input
            id="domainDataCAP"
            v-model="domainData.cap"
            name="cap"
            type="text"
            class="form-control"
            required
            maxlength="10"
            autocomplete="postal-code"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-5 col-lg-4">
          <label v-if="personaFisica" for="companyIndirizzo">{{ _t('Indirizzo residenza') }} *</label>
          <label v-else for="companyIndirizzo">{{ _t('Indirizzo sede') }} *</label>
          <input
            id="domainDataIndirizzo"
            v-model="domainData.indirizzo"
            name="indirizzo"
            type="text"
            class="form-control"
            required
            maxlength="250"
            autocomplete="street-address"
          >
        </div>

        <div class="form-group col-md-5 col-lg-2">
          <label for="domainDataCitta">{{ _t('Città') }} *</label>

          <input
            id="domainDataCitta"
            v-model="domainData.citta"
            name="citta"
            type="text"
            class="form-control"
            required
            maxlength="250"
          >
        </div>

        <div class="form-group col-md-2">
          <label v-if="domainData.stato == 'CH'" for="domainDataProvincia">{{ _t('Cantone') }} *</label>
          <label v-else for="domainDataProvincia">{{ _t('Provincia') }} *</label>
          <input
            id="domainDataProvincia"
            v-model="domainData.provincia"
            name="provincia"
            type="text"
            class="form-control"
            required
            maxlength="2"
          >
        </div>
      </div>

      <div class="row">
        <div v-if="!personaFisica" class="form-group col-md-5 col-lg-4">
          <label for="domainDataPiva">
            {{ _t('Partita IVA') }} <span v-if="piCfRequired">*</span>
          </label>
          <input
            id="domainDataPiva"
            v-model="domainData.partita_iva"
            name="partita_iva"
            type="text"
            :required="piCfRequired"
            class="form-control"
            maxlength="16"
          >
        </div>

        <div v-else class="form-group col-md-5 col-lg-4">
          <label for="domainDataCF">
            {{ _t('Codice fiscale') }} <span v-if="piCfRequired">*</span>
          </label>
          <input
            id="domainDataCF"
            v-model="domainData.codice_fiscale"
            name="codice_fiscale"
            type="text"
            :required="piCfRequired"
            class="form-control"
            maxlength="16"
          >
        </div>
      </div>
    </fieldset>

    <button type="submit" class="btn btn-primary" :disabled="sending">{{ _t('Invia') }}</button>
    &nbsp;
    <em>* {{ _t('Campi obbligatori') }}</em>
  </form>
</template>

<script lang="ts" setup>
import { type Ref, ref, computed } from 'vue';
import { $t as _t } from '@/i18n';
import { type DomainRegistrant, DomainRegistrantResource } from '@/resources';
import { useLoginStore } from '@/store/login';

import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import BtnRadio from '@/components/btn-radio.vue';
import countries from '../countries.json';

const props = defineProps<{
  idAzienda: number;
  domain: {
    id: number,
    dominio: string,
    codice: string,
  };
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const login = useLoginStore();
const sending = ref(false);

const domainData: Ref<DomainRegistrant> = ref({
  id: props.domain.id,
  usa_dati_fatturazione: login.complete,
  authcode: '',
  tipologia: 'Company',
  nome: '',
  cognome: '',
  ragione_sociale: '',
  stato: '',
  cap: '',
  indirizzo: '',
  citta: '',
  provincia: '',
  partita_iva: '',
  codice_fiscale: '',
});

const personaFisica = computed(() => domainData.value.tipologia == 'Private');
const piCfRequired = computed(() => props.domain.codice === 'REGIT' || domainData.value.stato == 'IT');

async function send(e: Event) {
  if (!(e.target instanceof HTMLFormElement)) {
    return;
  }

  sending.value = true;

  try {
    await new DomainRegistrantResource().post(domainData.value);

    emit('submit');
  } finally {
    sending.value = false;
  }
}
</script>
